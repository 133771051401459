import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import axios from 'axios';

import isStudentRole from 'utils/isStudentRole';
import useIdleLogout, { IdleLogoutInstance } from 'hooks/useIdleLogout';
import logout from 'utils/logout';
import { useAppDispatch, useAppSelector } from 'store';
import { saveLogMessage } from 'utils/saveLogMessage';
import StudentHome from 'student/controllers/Home/StudentHome';
import Course from 'student/controllers/Course/Course';
import SystemNotification from 'shared-components/ToasterNotification/SystemNotification';
import InstructorCourse from 'instructor/controllers/Course/Course';
import StudentGlobalNav from 'shared-components/GlobalNav/GlobalNav';
import ErrorBoundary from 'shared-components/ErrorBoundary/ErrorBoundary';
import { loadCommonPassiveData } from 'store/slices/passiveThunks';
const version = process.env.REACT_APP_VERSION || '0.0.0';

interface StudentAppState {
  modalIsOpen: boolean
}

/**
 * StudentAppContext is for storing state that needs to be available wherever we need it within the student view
 * initially because we need to be able to toggle aria-hidden for the rest of the content while the modal is open
 * but eventually it could be used for other student state
 */
export const StudentAppContext = createContext<{
  studentAppState: StudentAppState
  setStudentAppState: React.Dispatch<React.SetStateAction<StudentAppState>>
}>({
  studentAppState: {
    modalIsOpen: false,
  },
  setStudentAppState: () => {},
});

export default function StudentBase() {
  const { path } = useRouteMatch();
  const instructorStudentViewCourseId = useAppSelector((store) => store.state.instructorStudentViewCourseId);
  const enrollments = useAppSelector((store) => store.enrollments);
  const user = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();
  const [studentAppState, setStudentAppState] = useState({ modalIsOpen: false });
  const { modalIsOpen } = studentAppState;
  const isStudent = isStudentRole(user);
  useIdleLogout(user, IdleLogoutInstance.Student);

  /* Data Fetching Effect for Student base
   * When login or reauth is sucesssful and we have a user object,
   * Grab course information from the DB
   * it for use throughout the application.
   */
  const loadPassiveData = useCallback(async (userId: string) => {
    try {
      console.debug('Loading Passive Data For Student', userId);
      await dispatch(loadCommonPassiveData(userId));
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error) &&
          (error?.response?.status === 401 || error?.message.includes('401'))) {
        await logout(dispatch);
      } else {
        await saveLogMessage(`WEB ${version} - ${JSON.stringify(error, null, 2)}`);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (isStudent) {
      loadPassiveData(user.id);
    }
  }, [
    enrollments,
    instructorStudentViewCourseId,
    isStudent,
    loadPassiveData,
    user,
  ]);

  return (
    <StudentAppContext.Provider value={{ studentAppState, setStudentAppState }}>
      <div className="student-base" aria-hidden={modalIsOpen}>
        <div className="app_top">
          <StudentGlobalNav isInstructor={!!instructorStudentViewCourseId} userData={user} />
          <SystemNotification />
        </div>
        <div className="app__base">
          <ErrorBoundary>
            <Switch>
              <Route exact path={path}><StudentHome /></Route>
              <Route path={`${path}/course/:id`}><Course /></Route>
              <Route path={`${path}/course/:id`}><InstructorCourse /></Route>
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    </StudentAppContext.Provider>
  );
}
