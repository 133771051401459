import { Application, feathers, TransportConnection } from '@feathersjs/feathers';
import authenticationClient, { AuthenticationClientOptions } from '@feathersjs/authentication-client';
import rest from '@feathersjs/rest-client';
import axios from 'axios';

// this is copied from feathers docs, pretty sure the rest client is only used for login at the moment
const restClient = rest(process.env.REACT_APP_API_NEXT_URL);
const client = feathers();

export const addExtraQueryParams = (extraParams: { ltiUserId: string }) => {
  const interceptorId = axios.interceptors.request.use((config) => {
    config.params = { ...config.params, ...extraParams };
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  window.sessionStorage.setItem('interceptorId', JSON.stringify(interceptorId));
};

client.configure(restClient.axios(axios));

client.configure(authenticationClient({ storageKey: 'auth' }));

export default client;

// WIP, not used yet
// from: https://github.com/feathersjs/feathers-chat/blob/dove/feathers-chat-ts/src/client.ts
export interface Configuration {
  connection: TransportConnection<ServiceTypes>
}

export interface ServiceTypes {}

export type ClientApplication = Application<ServiceTypes, Configuration>

export const createClient = <Configuration = any>(
  connection: TransportConnection<ServiceTypes>,
  authenticationOptions: Partial<AuthenticationClientOptions> = {}
) => {
  const client: ClientApplication = feathers();

  client.configure(connection);
  client.configure(authenticationClient(authenticationOptions));
  client.set('connection', connection);

  // client.configure(userClient);
  // client.configure(messageClient);
  return client;
};
