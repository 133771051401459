import React from 'react';
import './CodonTextLogo.scss';

function CodonTextLogo() {
  return (
    <h1 className="codon-text-logo">
      <span>Codon</span>
      <span>Learning</span>
    </h1>
  );
}

export default CodonTextLogo;
