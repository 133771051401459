import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { socket } from 'socket';
import {
  FaChalkboardTeacher,
  FaCheck,
  FaCheckCircle,
  FaFrog,
  FaRegClock,
  FaTimes,
} from 'react-icons/fa';
import { GiFrog } from 'react-icons/gi';
import useElementSize from 'hooks/useElementSize';
import BetterBarChart, { BarData } from 'instructor/controllers/Course/AssessmentBuilderController/components/charts/BetterBarChart';
import ClarityRadioGroup from 'shared-components/ClarityRadioGroup/ClarityRadioGroup';
import CodonTextLogo from 'shared-components/CodonTextLogo/CodonTextLogo';
import Icon, { IconEnum } from 'shared-components/Icon';
import LoginBox from 'shared-components/Login/LoginBox';
import Spinner from 'shared-components/Spinner/Spinner';
import { ChildrenProp } from 'types/common.types';
import { ClarityEnum } from 'types/backend/studentAssessmentQuestionAttempts.types';
import './Tadpoll.scss';

enum SelectedAnswer {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

enum TadpollRole {
  Client = 'client',
  Host = 'host',
}

enum ClientAction {
  JoinRoom = 'client-joined',
  AnswerSubmitted = 'answer-submitted',
}

// This should only exist within the host, for managing button actions
enum HostAction {
  HostJoined = 'host-joined', // -> InLobby
  StartPoll = 'start-poll',
  OpenQuestion = 'open-question', // -> WaitingForAnswer
  CloseQuestion = 'close-question', // -> InBetween
  NextQuestion = 'next-question', // --> WaitingForAnswer
  EndPoll = 'end-poll',
  SetStage = 'set-stage',
}

enum RoomStage {
  Init = 'init',
  Connected = 'is-connected',
  InLobby = 'in-lobby', // waiting to start
  InHostlessLobby = 'in-hostless-lobby', // waiting for host
  WaitingForAnswer = 'answering',
  AnswerSent = 'answer-sent',
  InBetween = 'inbetween',
  Disconnected = 'is-disconnected',
}

// TODO: disable setStage via message, use side effects of actions in the client app
interface TadpollMessageBase {
  from: TadpollRole
  createdAt?: string
  userId: string
  roomId: string
  action: ClientAction | HostAction
}
interface TadpollHostMessage extends TadpollMessageBase {
  stage: RoomStage
  questionNumber?: number // gets sent on NextQuestion
}
interface TadpollClientAnswer extends TadpollMessageBase {
  answer: SelectedAnswer
  clarity: ClarityEnum
}
// should this be two separate interfaces

/**
 * TadpollShared
 **/

function ConnectionBar({ isConnected }: { isConnected: boolean }) {
  return (
    <div className="row middle-xs between-xs" style={{ width: '100%' }}>
      {isConnected ? <FaCheckCircle /> : <FaTimes />}
      <small>
        {isConnected
          ? <button title="click to disconnect" onClick={() => socket.disconnect()}>Connected</button>
          : <button onClick={() => socket.connect()}>Disconnected</button>
        }
      </small>
    </div>
  );
}

function RoomStageWrap({ children, className = '' }: {
  children: ChildrenProp
  className?: string
}) {
  return (
    <div className={`tadpoll__client-stage ${className}`}>
      {children}
    </div>
  );
}

function TadpollButton({ children, className = '', ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button className={`tadpoll-button ${className}`} {...props}>
      {children}
    </button>
  );
}

/**
 * TadpollClient
 **/

function TadpollClient({ fakeUserId }: { fakeUserId?: string }) {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [roomStage, setRoomStage] = useState<RoomStage>(RoomStage.Init);
  const [activeQuestionNumber, setActiveQuestionNumber] = useState(1);

  useEffect(() => {
    function onConnect() {
      console.info('onConnect');
      if (roomStage === RoomStage.Init) {
        setRoomStage(RoomStage.Connected);
      }
      setIsConnected(true);
    }

    function onDisconnect() {
      console.info('onDisconnect');
      setIsConnected(false);
    }

    const onMessageReceived = ({ action, questionNumber, stage, from }: TadpollHostMessage) => {
      if (from === TadpollRole.Host) {
        switch (action) {
          case HostAction.SetStage: {
            if (!!questionNumber) {
              setActiveQuestionNumber(questionNumber);
            }
            setRoomStage(stage);
            break;
          }
        }
      }
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('tadpoll-messages created', ({ contextData }) => onMessageReceived(contextData));

    socket.on('connect_error', (err: any) => {
      console.error('connect_error', err.message, err);
    });

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('tadpoll-messages created', onMessageReceived);
    };
  }, [roomStage]);

  const sendClientMessage = (msgData: TadpollMessageBase) => socket.emit(
    'create',
    'tadpoll-messages',
    msgData,
    (error: unknown, message: unknown) => {
      // TODO: if message received and stage doesn't match, update local stage
      if (error) {
        console.error('error', error);
      }
      return message;
    }
  );

  const sendAction = async (action: ClientAction) => {
    const msgData: TadpollMessageBase = {
      from: TadpollRole.Client,
      action,
      userId: fakeUserId || 'client-user-id',
      roomId: 'room-id',
    };
    return sendClientMessage(msgData);
  };
  const sendClientAnswer = async (selectedAnswer: SelectedAnswer, clarity: ClarityEnum) => {
    const msgData: TadpollClientAnswer = {
      from: TadpollRole.Client,
      action: ClientAction.AnswerSubmitted,
      answer: selectedAnswer,
      clarity,
      userId: fakeUserId || 'client-user-id',
      roomId: 'room-id',
    };
    setRoomStage(RoomStage.AnswerSent);
    return sendClientMessage(msgData);
  };

  const handleJoinRoom = async () => {
    const joinResponse = await sendAction(ClientAction.JoinRoom);
    // TODO: figure out how to get message back from socket.emit response, currently returns an instance of Socket
    console.debug('joinResponse', joinResponse);
    if (joinResponse) {
      setRoomStage(RoomStage.InHostlessLobby);
    }
  };

  // some of the stages should display a simple, repeatable static pattern, an icon and a message.
  // these are stages where the user has no interactions available
  const clientStaticPages = {
    [RoomStage.Init]: { icon: <Spinner />, message: 'Loading...' },
    [RoomStage.InHostlessLobby]: { icon: <FaRegClock />, message: 'Waiting for host to join' },
    [RoomStage.InLobby]: { icon: <FaFrog />, message: 'In lobby, waiting for host to start' },
    [RoomStage.InBetween]: { icon: <FaChalkboardTeacher />, message: 'Question closed. Waiting for host.' },
    [RoomStage.AnswerSent]: { icon: <FaCheck />, message: 'Answer submitted!' },
  };

  const renderRoomStage = (stage: RoomStage) => {
    switch (stage) {
      case RoomStage.Init:
      case RoomStage.InLobby:
      case RoomStage.InHostlessLobby:
      case RoomStage.InBetween: {
        const { icon, message } = clientStaticPages[stage];
        return (
          <RoomStageWrap>
            <div className="client-stage__icon">{icon}</div>
            <div className="client-stage__message">{message}</div>
          </RoomStageWrap>
        );
      }
      case RoomStage.AnswerSent: {
        return (
          <RoomStageWrap>
            <div className="client-stage__icon success fadeInDown"><FaCheck /></div>
            <div className="client-stage__message">Answer submitted!</div>
          </RoomStageWrap>
        );
      }
      case RoomStage.Connected: {
        return (
          <RoomStageWrap>
            <TadpollButton onClick={handleJoinRoom}>Join Tadpoll</TadpollButton>
          </RoomStageWrap>
        );
      }
      case RoomStage.WaitingForAnswer: {
        return (
          <div className="client-stage__waiting-for-answer">
            <div className="client-stage__waiting-for-answer__header row center-xs">
              <div className="client-stage__waiting-for-answer__question-number">
                <small>Question</small>
                <div>{activeQuestionNumber}</div>
              </div>
            </div>
            <TadpollMultiChoice handleSubmitChoice={sendClientAnswer} />
          </div>
        );
      }
    }
  };

  return (
    <div className="tadpoll-client" data-connected={isConnected} data-user-id={fakeUserId}>
      <div className="client-stage" data-client-stage={roomStage}>
        {renderRoomStage(roomStage)}
      </div>
      <small className="tadpoll-client__status">roomStage: {roomStage}</small>
    </div>
  );
}

function TadpollMultiChoice({ handleSubmitChoice }: { handleSubmitChoice: (choice: SelectedAnswer, clarity: ClarityEnum) => void }) {
  const [choiceSelected, setChoiceSelected] = useState<SelectedAnswer | null>(null);
  const [questionClarity, setQuestionClarity] = useState<ClarityEnum | null>(null);
  const choices = Object.values(SelectedAnswer);
  const submitDisabled = !choiceSelected || !questionClarity;
  return (
    <div className="tadpoll__multi-choice">
      <div className="tadpoll__multi-choice__buttons">
        {choices.map((choice) => (
          <button className="tadpoll__multi-choice-button"
            key={choice}
            onClick={() => setChoiceSelected(choice)}
            data-selected={choiceSelected === choice}
          >
            {choice}
          </button>
        ))}
      </div>
      <div className="tadpoll__multi-choice__action-bar">
        <ClarityRadioGroup id="tadpoll-clarity" clarity={questionClarity} onChange={setQuestionClarity} />
        <TadpollButton
          disabled={submitDisabled}
          onClick={() => !!choiceSelected && !!questionClarity && handleSubmitChoice(choiceSelected, questionClarity)}
        >
          Submit
        </TadpollButton>
      </div>
    </div>
  );
}


/**
 * TadpollHost
 **/

interface StateMachineItem {
  [key: string]: {
    availableActions: Array<HostAction>
    availableStages: Array<RoomStage>
  }
}

const roomStageStateMachineForHost: StateMachineItem = {
  [RoomStage.Init]: {
    availableActions: [HostAction.HostJoined],
    availableStages: [RoomStage.Connected],
  },
  [RoomStage.Connected]: {
    availableActions: [HostAction.HostJoined],
    availableStages: [RoomStage.InLobby, RoomStage.InHostlessLobby],
  },
  [RoomStage.InHostlessLobby]: {
    availableActions: [HostAction.HostJoined],
    availableStages: [RoomStage.InLobby],
  },
  [RoomStage.InLobby]: {
    availableActions: [HostAction.StartPoll],
    availableStages: [RoomStage.WaitingForAnswer],
  },
  [RoomStage.WaitingForAnswer]: {
    availableActions: [HostAction.CloseQuestion],
    availableStages: [RoomStage.AnswerSent],
  },
  [RoomStage.AnswerSent]: {
    availableActions: [HostAction.CloseQuestion],
    availableStages: [RoomStage.InBetween],
  },
  [RoomStage.InBetween]: {
    availableActions: [HostAction.NextQuestion, HostAction.EndPoll],
    availableStages: [RoomStage.WaitingForAnswer],
  },
  [RoomStage.Disconnected]: {
    availableActions: [],
    availableStages: [],
  },
};

// question aggregation, for showing current question answer barchart and displaying past questions
interface AggregatedAnswer {
  selectedAnswer: SelectedAnswer
  selectedClarity: ClarityEnum
  userId: string
}
interface PastQuestionData {
  questionNumber: number
  answers: Array<AggregatedAnswer>
  answersDistribution: Array<BarData>
  muddyPercentage: number
}

function getGreenGradientFromDecimal(decimalPerc: number) {
  const colorLo = 'FFFFFF'; // red
  const colorHi = '22DD22'; // lightgreen
  const hex = (x: number) => {
    const numString = x.toString(16);
    return numString.length === 1 ? `0${numString}` : numString;
  };

  const r = Math.ceil(parseInt(colorHi.substring(0, 2), 16) * decimalPerc + parseInt(colorLo.substring(0, 2), 16) * (1 - decimalPerc));
  const g = Math.ceil(parseInt(colorHi.substring(2, 4), 16) * decimalPerc + parseInt(colorLo.substring(2, 4), 16) * (1 - decimalPerc));
  const b = Math.ceil(parseInt(colorHi.substring(4, 6), 16) * decimalPerc + parseInt(colorLo.substring(4, 6), 16) * (1 - decimalPerc));
  return `#${hex(r)}${hex(g)}${hex(b)}`;
}

function TadpollClarityDisplay({ clarityTuple: [muddy, clear] }: { clarityTuple: Array<number> }) {
  const [barRef, { width }] = useElementSize();
  const total = muddy + clear;
  let leftWidthDecimal = muddy / total;
  let rightWidthDecimal = clear / total;
  const noDataYet = !total;
  if (noDataYet) {
    leftWidthDecimal = 0.5;
    rightWidthDecimal = 0.5;
  }
  const leftWidthPerc = leftWidthDecimal * 100;
  const rightWidthPerc = rightWidthDecimal * 100;
  return (
    <div className="tadpoll__clarity-display">
      <div className="tadpoll__clarity-display__title" >
        Clarity Distribution
      </div>
      <div className="tadpoll__clarity-display__bar" data-empty={noDataYet} ref={barRef}>
        <div data-hidden={!leftWidthPerc} style={{ width: `${leftWidthDecimal * width}px` }}>
          <span>{Math.round(leftWidthPerc)}%</span>
          <Icon which={IconEnum.Muddy} />
        </div>
        <div data-hidden={!rightWidthPerc} style={{ width: `${rightWidthDecimal * width}px` }}>
          <Icon which={IconEnum.Clear} />
          <span>{Math.round(rightWidthPerc)}%</span>
        </div>
      </div>
      {noDataYet && <small>(awaiting responses)</small>}
    </div>
  );
}

function TadpollHost() {
  const [isConnected, setIsConnected] = useState(socket.connected);

  // question-related state
  const [aggregatedAnswers, setAggregatedAnswers] = useState<Array<AggregatedAnswer>>([]);
  const [pastQuestionData, setPastQuestionData] = useState<Array<PastQuestionData>>([]);
  const [activeQuestionNumber, setActiveQuestionNumber] = useState(1);

  // room status
  const [clientsInRoom, setClientsInRoom] = useState<Array<string>>([]);
  const [mostRecentMessage, setMostRecentMessage] = useState('');
  const [activeRoomStage, setActiveRoomStage] = useState<RoomStage>(RoomStage.Init);

  const answersDistribution = aggregatedAnswers.reduce((acc: Array<BarData>, cur) => {
    const existingDataItem = acc.find((item) => item.name === cur.selectedAnswer);
    if (existingDataItem) {
      existingDataItem.value = existingDataItem.value + 1;
    }
    return acc;
  }, [
    { name: SelectedAnswer.A, value: 0 },
    { name: SelectedAnswer.B, value: 0 },
    { name: SelectedAnswer.C, value: 0 },
    { name: SelectedAnswer.D, value: 0 },
    { name: SelectedAnswer.E, value: 0 },
  ]);

  const clarityDistribution = aggregatedAnswers.reduce((acc, { selectedClarity }) => {
    const [muddyCount, clearCount] = acc;
    if (selectedClarity === ClarityEnum.Muddy) {
      const updatedMuddyCount = muddyCount + 1;
      return [updatedMuddyCount, clearCount];
    } else {
      const updatedClearCount = clearCount + 1;
      return [muddyCount, updatedClearCount];
    }
  }, [0, 0]);


  useEffect(() => {
    function onConnect() {
      console.info('onConnect');
      setIsConnected(true);
    }

    function onDisconnect() {
      console.info('onDisconnect');
      setIsConnected(false);
    }

    const onMessageReceived = (received: TadpollMessageBase) => {
      const { action } = received;
      if (received.from === TadpollRole.Client) {
        switch (action) {
          case ClientAction.AnswerSubmitted: {
            const { answer, clarity, createdAt, userId } = received as TadpollClientAnswer;
            setAggregatedAnswers((prev) => [...prev, {
              selectedAnswer: answer,
              selectedClarity: clarity,
              userId,
            }]);
            // HACK: update clients in room here too, since it's currently possible to answer without joining
            // that won't be the case for long
            setClientsInRoom((prev) => [...new Set([...prev, userId])]);
            if (createdAt) {
              setMostRecentMessage(createdAt);
            }
            break;
          }
          case ClientAction.JoinRoom: {
            const { userId } = received;
            setClientsInRoom((prev) => [...new Set([...prev, userId])]);
            break;
          }
        }
      }
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('tadpoll-messages created', ({ contextData }) => onMessageReceived(contextData));

    socket.on('connect_error', (err: any) => {
      console.error('connect_error', err.message, err);
    });

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('tadpoll-messages created', onMessageReceived);
    };
  }, []);

  // this is verbose on purpose, currently the host just sends one kind of action
  // but it is likely that there will be more host actions in future
  const sendHostMessage = async (msgData: TadpollHostMessage) => socket.emit(
    'create',
    'tadpoll-messages',
    msgData,
    (error: unknown, result: unknown) => {
      if (error) {
        // TODO: handle socket error
        throw new Error(`socket error: ${JSON.stringify(error)}`);
      }
      console.info('result', result);
      return result;
    }
  );

  const updateRoomStage = async (stage: RoomStage, questionNumber?: number) => {
    setActiveRoomStage(stage);
    const msgData: TadpollHostMessage = {
      from: TadpollRole.Host,
      action: HostAction.SetStage,
      questionNumber,
      stage,
      userId: 'instructor',
      roomId: 'room-id',
    };
    return sendHostMessage(msgData);
  };

  const handleActionClick = async (action: HostAction) => {
    switch (action) {
      case HostAction.NextQuestion: {
        const [muddy, clear] = clarityDistribution;
        setPastQuestionData((prev) => [...prev, {
          questionNumber: activeQuestionNumber,
          answers: aggregatedAnswers,
          muddyPercentage: Math.round((muddy / (muddy + clear)) * 100),
          answersDistribution,
        }]);
        const newQuestionNumber = activeQuestionNumber + 1;
        setActiveQuestionNumber(newQuestionNumber);
        setAggregatedAnswers([]);
        updateRoomStage(RoomStage.WaitingForAnswer, newQuestionNumber);
        break;
      }
      case HostAction.HostJoined: {
        // TODO: if server RoomStage is out of sync with local state (e.g. if the host needs to reload) update local `activeRoomStage` with remote data
        updateRoomStage(RoomStage.InLobby);
        break;
      }
      case HostAction.StartPoll:
      case HostAction.OpenQuestion: {
        updateRoomStage(RoomStage.WaitingForAnswer);
        break;
      }
      case HostAction.CloseQuestion: {
        updateRoomStage(RoomStage.InBetween);
        break;
      }
    }
  };

  return (
    <div className="tadpoll-host">
      <div className="tadpoll-host__live-question">
        <div className="tadpoll-host__live-question__header">
          <div key={mostRecentMessage} className="header__indicator">
            <div className="activity-indicator"><GiFrog size={28} /></div>
          </div>
          <div className="tadpoll-host__live-question__header__question-number">Question {activeQuestionNumber}</div>
        </div>
        <BetterBarChart data={answersDistribution} handleHover={() => {}} />
        <TadpollClarityDisplay clarityTuple={clarityDistribution} />
        <div className="tadpoll__create-room">
          <button className="create-room">Create Room</button>
        </div>
      </div>
      <h3>Host Actions</h3>
      <div className="tadpoll-host__actions">
        {Object.values(HostAction).map((action) => {
          const { availableActions = [] } = roomStageStateMachineForHost[activeRoomStage];
          const actionDisabled = !availableActions.length || !availableActions.includes(action as HostAction);
          return (
            <TadpollButton
              key={action}
              onClick={() => handleActionClick(action)}
              disabled={actionDisabled}
              data-hidden={actionDisabled}
              data-host-action={action}
            >
              {action}
            </TadpollButton>
          );
        })}
      </div>
      <div className="past-question">
        <table className="tadpoll-host__past-questions">
          <thead>
            <tr>
              <td></td>
              {Object.values(SelectedAnswer).map((answer) => (
                <td key={answer}>{answer}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {pastQuestionData.map(({ answers, answersDistribution: answersDist, muddyPercentage, questionNumber }) => {
              const totalAnswers = answers.length;
              const arrayOfPercentages = answersDist.map(({ name, value }) => ({
                name,
                decimal: value / totalAnswers,
                percentage: Math.round((value / totalAnswers) * 100),
              }));
              return (
                <tr key={questionNumber}>
                  <td>Q{questionNumber}</td>
                  {arrayOfPercentages.map(({ name, decimal, percentage }) => (
                    <td style={{ backgroundColor: getGreenGradientFromDecimal(decimal) }} key={name}>{percentage}%</td>
                  ))}
                  <td>{muddyPercentage}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <h3>Status</h3>
        <ul>
          <li>Active Client Stage: {activeRoomStage}</li>
          <li>Clients in room: {clientsInRoom.length}</li>
        </ul>
      </div>
      <hr />
      <ConnectionBar isConnected={isConnected} />
    </div>
  );
}

/**
 * TadpollBase
 **/
function TadpollBox({
  children,
  hideSubHead = false,
  title,
}: {
  children?: React.ReactNode | Array<React.ReactNode>
  hideSubHead?: boolean
  title: string
}) {
  // useDocumentTitle(title);
  return (
    <main className="tadpoll__main row top-xs middle-sm around-sm">
      <div className="tadpoll__box col-xs-12 col-sm-5 col-md-4 col-lg-3">
        <CodonTextLogo />
        {!hideSubHead && <h2>{title}</h2>}
        {children}
      </div>
    </main>
  );
}

function TadpollBase() {
  const { path }: { path: string } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/multiplex`}>
        <TadpollMultiplex />
      </Route>
      <Route exact path={`${path}/client`}>
        <TadpollBox title="Tadpoll Client" hideSubHead>
          <TadpollClient />
        </TadpollBox>
      </Route>
      <Route exact path={`${path}/host`}>
        <TadpollBox title="Tadpoll Host">
          <TadpollHost />
        </TadpollBox>
      </Route>
      <Route exact path={path}>
        <div className="tadpoll-base row">
          <div className="col-xs-6">
            <TadpollBox title="Tadpoll Client" hideSubHead>
              <TadpollClient />
            </TadpollBox>
          </div>
          <div className="col-xs-6">
            <LoginBox title="Tadpoll Host">
              <TadpollHost />
            </LoginBox>
          </div>
        </div>
      </Route>
    </Switch>
  );
}

function TadpollMultiplex() {
  const multiplexUserIdArray = [
    ['abc', 'def', 'hij'],
    ['klm', 'nop', 'qrs'],
    ['tuv', 'wxy', 'zzz'],
  ];
  return (
    <div className="row">
      <div className="col-xs-3"><TadpollHost /></div>
      <div className="col-xs-9">
        {multiplexUserIdArray.map((clientRowArray) => (
          <div className="row" key={JSON.stringify(clientRowArray)}>
            {clientRowArray.map((fakeUserId) => (
              <div className="col-xs-4" key={fakeUserId}>
                <TadpollClient fakeUserId={fakeUserId} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TadpollBase;
