import React from 'react';
import styles from 'style-config.scss';
import './Charts.scss';

export interface BarData {
  name: string
  value: number
}

function BetterBarGroup({
  barData,
  color,
  barHeight,
  increment = 30,
}: {
  barData: BarData
  color: string
  barHeight: number
  increment?: number
}) {
  const barPadding = 2;
  const widthScale = (dd: number) => dd * increment;


  const width = widthScale(barData.value);
  const yMid = barHeight * 0.5;
  const isZero = barData.value === 0;
  return (
    <g className="bar-group">
      <text
        className="name-label"
        x="-6"
        y={yMid}
        alignmentBaseline="middle"
      >
        {barData.name}
      </text>
      <rect y={barPadding * 0.5} width={width} height={barHeight - barPadding} fill={color} />
      <text
        className={`value-label ${isZero ? 'is-zero' : ''}`}
        x={isZero ? width + 8 : width - 8}
        y={yMid}
        alignmentBaseline="middle"
      >
        {barData.value}
      </text>
    </g>
  );
}

function BetterBarChart({ data, handleHover }: {
  data: Array<BarData>
  handleHover: (info: string) => void
}) {
  const barHeight = 40;
  const totalHeight = (barHeight + 5) * data.length;

  const highestValue = data.reduce((acc: number, cur) => {
    if (cur.value > acc) {
      return cur.value;
    }
    return acc;
  }, 0);

  // TODO: make this more dynamic rather than a hardcoded width
  const increment = Math.min(200 / highestValue, 30);

  return (
    <svg width="100%" height={totalHeight}>
      <g className="container">
        <g className="chart" transform="translate(60, 0)">
          {
            data.map((barData, i) => (
              <g
                transform={`translate(0, ${i * barHeight})`}
                key={`bargroup-${barData.name}`}
                onMouseEnter={() => handleHover(barData.name)}
                onMouseLeave={() => handleHover('')}
              >
                <BetterBarGroup
                  barData={barData}
                  barHeight={barHeight}
                  color={i % 2 ? styles.purple : styles.blue }
                  increment={increment}
                />
              </g>
            ))
          }
        </g>
      </g>
    </svg>
  );
}


export default BetterBarChart;
